import { Component, OnDestroy, OnInit } from '@angular/core'
import * as fromCoreStore from '@app/core/store'
import * as fromExpenseStore from '@app/shared/store'
import * as fromAuthStore from '@app/users/store'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { map, takeUntil, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { User } from '@app/shared/models/user.model'
import { Expense, ExpenseStats, getTripStr, SocketService } from '@app/shared'
import { ActivatedRoute } from '@angular/router'
import { OpenModal } from '@app/modals/store'
import { AccountChart } from '@app/shared/models/account-chart.model'

@Component({
    selector: 'app-expenses',
    template: `
    <div class="list__container">
        <div class="mr-auto ml-auto" style="width:98%;">
            <app-expense-list
                [expenses]="expenses$ | async"
                [filters]="filters$ | async"
                [totalCount]="totalCount$ | async"
                [stats]='stats$ | async'
                [accountsCharts]='accountsCharts$ | async'
                [selectedId]="selectedId$ | async"
                [currentUser]='currentUser$ | async'
                [categories]='categories$ | async'
                [loading]='loading$ | async'
                [loaded]='loaded$ | async'
            ></app-expense-list>
        </div>
    </div>
    `,
    styles: [
        `
            @tailwind base;
            @tailwind components;
            @tailwind utilities;
            .list__container {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        `
    ]
})
export class ExpensesComponent implements OnInit, OnDestroy {
    expenses$: Observable<any>
    users$: Observable<User[]>
    filters$: Observable<any>
    selectedId$: Observable<any>
    totalCount$: Observable<any>
    stats$: Observable<ExpenseStats[]>
    categories$: Observable<any>
    accountsCharts$: Observable<AccountChart[]>
    loading$: Observable<boolean>
    loaded$: Observable<boolean>
    isLoggedIn
    filters

    currentUser$: Observable<any>

    destroyed$ = new Subject<boolean>()


    constructor(
        private store: Store<fromCoreStore.State>,
        private _ActivatedRoute: ActivatedRoute,
        private _SocketService: SocketService
    ) { }

    ngOnInit(): void {
        this.store.dispatch(new fromCoreStore.GetExpenseCategories({}))
        const params = this._ActivatedRoute.snapshot
        if (params.params.expenseId) {
            this.store.dispatch(
                new OpenModal({
                    type: 'EXPENSE',
                    props: {
                        _id: params.params.expenseId
                    },
                })
            )
            // this._Router.navigate(['/expenses']);
        }
        this.currentUser$ = this.store.select(fromAuthStore.getUser).pipe(
            map((user) => {
                if (user) {
                    this.store.dispatch(new fromCoreStore.GetAccountsCharts({ filters: { companyId: user.company._id } }))
                }
                return user
            })
        )
        this.expenses$ = this.store.select(fromCoreStore.selectAllExpensesWithLocked).pipe(
            map((expenses) => {
                return expenses.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map(e => {
                    if (e?._trip) {
                        e._trip._str = getTripStr(e._trip)
                        e._trip._strWithBreak = getTripStr(e._trip, 'breakLine')
                    }
                    return e
                })
            }

            )
        )
        this._SocketService.items$.pipe(takeUntil(this.destroyed$)).subscribe((sockets) => {
          if (sockets && sockets.data && sockets.socket?.id) {
            this.store.dispatch(new fromCoreStore.GetLockedItemsSuccess({ items: sockets?.data?.data}))
          }
        });
        this.store.select(fromAuthStore.getLoggedIn).pipe(
            takeUntil(this.destroyed$),
            tap(loggedIn => {
                this.isLoggedIn = loggedIn
            })
        ).subscribe()
        this.loading$ = this.store.select(fromExpenseStore.getExpensesLoading)
        this.loaded$ = this.store.select(fromExpenseStore.getExpensesLoaded)
        this.selectedId$ = this.store.select(fromExpenseStore.selectCurrentExpenseId)
        this.filters$ = this.store.select(fromExpenseStore.selectExpenseFilter)
        this.totalCount$ = this.store.select(fromExpenseStore.selectExpenseTotalCount)
        this.stats$ = this.store.select(fromExpenseStore.selectExpenseStats)
        this.accountsCharts$ = this.store.select(fromCoreStore.getAccountChartsByParent)
        this.categories$ = this.store.select(fromExpenseStore.selectExpenseCategories)
        this.filters$.pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
            debounceTime(500),
            tap(filters => {
                if (this.isLoggedIn) {
                    this.store.dispatch(new fromExpenseStore.GetExpenses(filters ? filters : {}))
                }
            })
        ).subscribe()
    }


    ngOnDestroy(): void {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}
