import { Component, OnDestroy, OnInit, HostListener, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'
import * as fromShopStore from '@app/shop/store'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { OpenModal } from '@app/modals/store'
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms'
import { ExpenseQueryFields, FOLLOW_UP_RESOURCE } from '@app/shared'
import { MULTI_SELECT_PRESET_VALUE_CONFIG } from '@app/shared/buttons/select.config'
import { multiselectGetValues } from '@app/shared'

interface _ExpenseQueryFields extends ExpenseQueryFields {
  vendorName?: string, employeeName?: string
}

@Component({
    selector: 'app-expense-filter',
    templateUrl: './expenses-filter.component.html',
    styleUrls: ['./expenses-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseFilterComponent implements OnInit {
    @Output()
    onUpdate = new EventEmitter<any>();
    @Output()
    onSet = new EventEmitter<Partial<_ExpenseQueryFields>>();
    @Output()
    onReset = new EventEmitter<any>();
    @Input()
    filters: _ExpenseQueryFields
    @Input()
    set categories(val) {
        if (val) {
            this.categoriesArray = Object.keys(val).map(key => {
                return { key: key, name: val[key] }
            })
        }
    }

    @Input()
    currentUser
    @Input()
    set accountsCharts(val){
        if (val && !this?._accountsCharts?.length) {
            this._accountsCharts = val.map(g => {
                g.accounts.filter(a => a?.name).map((a) => {
                    a._name = a?._contact?.name ? `${a.name}, ${a?._contact?.name}` : a.name
                    return a
                })
                return g
            })
        }
    }
    get accountsCharts() {
        return this._accountsCharts
    }
    _accountsCharts
    form
    categoriesArray

    dateType = 'expense'
    data = {}


    FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE
    multiSelectConfig: any = {
        ...MULTI_SELECT_PRESET_VALUE_CONFIG,
        "allowSearchFilter": false,
        "enableCheckAll": false
    }

    paymentTypes = [
        { key: 'ach', name: 'ACH / Zelle / Venmo' },
        { key: 'check', name: 'Check' },
        { key: 'companyCard', name: 'Company Card' },
        { key: 'cash', name: 'COP/COD Cash' },
        { key: 'reimburse', name: 'Driver Reimburse' }
    ]
    tags = [{
        value: 'WARNING', label: 'WARNING'
    }, {
        value: 'REFUND', label: 'REFUND'
    },
    {
        value: 'MISSING_RECEIPTS', label: 'MISSING RECEIPTS'
    }]
    randomId

    constructor(
        private fb: UntypedFormBuilder,
        private coreStore: Store<fromCoreStore.State>,
        private cdRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.randomId = Math.random().toString(36).substring(2, 15)
    }

    toggleResources = (resource: string) => {
        if (this.filters?.resource?.includes(resource)) {
            this.onSet.emit({ resource: '' })
            this.filters.resource = ''
        } else {
            this.onSet.emit({ resource: [resource].join(',') })
        }
    }
    setTags(tag) {
        tag = tag.length ? multiselectGetValues(tag) : tag.value
        let tags: any = this.filters?.tags
        tags = (Array.isArray(tags) ? tags : [])
        if (tags.includes(tag)) {
            tags = tags.filter(i => i !== tag) || []
        } else {
            if (Array.isArray(tag)) {
                tags = [tag]
            } else {
                tags.push(tag)
            }
        }
        this.onSet.emit({ tags })
    }
    setDateType(event) {
        this.filters = this.filters ? this.filters : {}

        this.filters[`${event}DateStart`] = this.filters[`${this.dateType}DateStart`]
        this.filters[`${event}DateEnd`] = this.filters[`${this.dateType}DateEnd`]

        if (this.filters[`${this.dateType}DateStart`]) {
            delete this.filters[`${this.dateType}DateStart`]
        }
        if (this.filters[`${this.dateType}DateEnd`]) {
            delete this.filters[`${this.dateType}DateEnd`]
        }
        this.dateType = event
        this.onSet.emit(this.filters)
    }
    setData(event) {
        this.filters = this.filters ? this.filters : {}
        this.filters[`${this.dateType}DateStart`] = event?.dateStart ? event?.dateStart : ''
        this.filters[`${this.dateType}DateEnd`] = event?.dateEnd ? event?.dateEnd : ''
        this.data = { ...this.data, ...event }
        this.onSet.emit(this.filters)
    }

}
